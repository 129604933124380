import React from 'react'
import {MammQuestionnaire} from './MammQuestionnaire.jsx'
import LinkQuestionnaire from './LinkQuestionnaire.jsx'
import UrgentCareQuestionnaire from './UrgentCareQuestionnaire.jsx'

const QuestionnaireModal = (props) => {
  console.log('QuestionnaireModal')
  let component = <div />
  switch (props.questionnaire) {
    case 'mamm':
      component = <MammQuestionnaire />
      break
    case 'link':
      component = <LinkQuestionnaire {...props} />
      break
    case 'uc':
      component = <UrgentCareQuestionnaire {...props} />
      break
  }
  return component
}

export default QuestionnaireModal
