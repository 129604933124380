import URLS from '../../../shared/constants/urls'
import { MAMMO_VISIT_REGULAR, MAMMO_VISIT_IMPLANTS, MAMMO_VISIT_ASSISTANCE } from '../../../shared/constants/api-helpers'
import { formatPhoneNumber, cleanNumber } from '../../../shared/utility-functions/phoneHelpers'

let currentQuestion = 1;

const isSuperMammoScheduling = window.sessionStorage.getItem('isSuperMammoScheduling');
var locationContactNo = window.sessionStorage.getItem('sas-location');
locationContactNo = locationContactNo? JSON.parse(locationContactNo).Phone:'';

const getCurrentQuestion = (age) => {
  currentQuestion = isSuperMammoScheduling && currentQuestion == 2 && age > 51 ? currentQuestion + 2 : currentQuestion;
  let question = questions.find(q => q.id === currentQuestion)
  if (!question) {
    return 'done'
  } else if (question.id === 1) {
    question.answers[1].set = age < 51 ? 2 : 4
  }
  question.number = setNumber(age, question.id)
  return question
}

const setNumber = (age, id) => {
  if ((id >= questions.length - 2) && age >= 51) {
    return id - 2
  }
  return id
}

const answerQuestion = (action) => {
  if (typeof action.set === 'string') {
    window.sessionStorage.setItem('VisitTypeID', action.set)
    currentQuestion += 1
  } else {
    currentQuestion = action.set
  }
  if (currentQuestion === questions.length + 1) {
    let event = document.createEvent('Event')
    event.initEvent('questionnaireCompleted', true, true)
    window.dispatchEvent(event)
  }
}

export const superMammogramGeneralCannotScheduleNotice = {
  text: "Additional details are needed before scheduling your mammogram. Please contact your provider’s office.",
  title:"Contact your provider",
  actions: [
    {
      url: `${URLS.mychartUrl}/Clinical/CareTeam`,
      urlTitle: "LOG INTO MYCHART",
      className: "btn-outline",
    },
    {
      url: URLS.findADoctorUrl,
      urlTitle: "FIND A DOCTOR",
      className: "btn-solid",
    },
  ],
};

export const mammogramGeneralCannotScheduleNotice = {
  text: "Additional details are needed before scheduling your mammogram. Please contact your provider’s office.",
  url: URLS.findADoctorUrl,
  urlTitle: "Find a Doctor",
};

export const mammogramDiagnosticOrderCannotScheduleNotice = {
  text: `We need to ask you a few questions about your physician’s order. Please call the OhioHealth mammography location for help scheduling`,
  url: isSuperMammoScheduling ? `tel:${locationContactNo}` : "/locations/mammography/",
  urlTitle: isSuperMammoScheduling ? formatPhoneNumber(locationContactNo) : "VIEW MAMMOGRAPHY LOCATIONS",
  className: isSuperMammoScheduling ? "location-contact-no" : "",
  title: isSuperMammoScheduling ? "Please call to discuss your mammogram" : "",
};

const questions = [
  {
    id: 1,
    question: 'Do you have an order from your provider for a diagnostic mammogram?',
    answers: isSuperMammoScheduling 
              ? [{ text: 'no', set: 2 }, { text: 'yes', error: mammogramDiagnosticOrderCannotScheduleNotice}]
              : [{ text: 'yes', error: mammogramDiagnosticOrderCannotScheduleNotice }, { text: 'no', set: 2 }]
  },
  {
    id: 2,
    question: 'Are you currently pregnant?',
    answers: isSuperMammoScheduling
              ? [{ text: 'no', set: 3 }, { text: 'yes', error: superMammogramGeneralCannotScheduleNotice }]
              : [{ text: 'yes', error: mammogramGeneralCannotScheduleNotice }, { text: 'no', set: 3 }]
  },
  {
    id: 3,
    question: 'Are you currently breastfeeding, or have breastfed within the last six months?',
    answers: isSuperMammoScheduling
              ? [{ text: 'no', set: 4 }, { text: 'yes', error: superMammogramGeneralCannotScheduleNotice }]
              : [{ text: 'yes', error: mammogramGeneralCannotScheduleNotice }, { text: 'no', set: 4 }]
  },
  {
    id: 4,
    question: 'Do you have any new problems or complaints with either breast?',
    answers: isSuperMammoScheduling
              ? [{ text: 'no', set: 5 }, { text: 'yes', error: superMammogramGeneralCannotScheduleNotice }]
              : [{ text: 'yes', error: mammogramGeneralCannotScheduleNotice }, { text: 'no', set: 5 }]
  },
  {
    id: 5,
    question: 'Do you have breast implants?',
    answers: isSuperMammoScheduling
              ? [{ text: 'no', set: MAMMO_VISIT_REGULAR }, { text: 'yes', set: MAMMO_VISIT_IMPLANTS }]
              : [{ text: 'yes', set: MAMMO_VISIT_IMPLANTS }, { text: 'no', set: MAMMO_VISIT_REGULAR }]
  },
  {
    id: 6,
    question: 'Do you need special assistance or require mobility devices, such as a wheelchair or walker?',
    answers: isSuperMammoScheduling
              ? [{ text: 'no', set: 7 }, { text: 'yes', set: MAMMO_VISIT_ASSISTANCE }]
              : [{ text: 'yes', set: MAMMO_VISIT_ASSISTANCE }, { text: 'no', set: 7 }]
  }
]

const MammService = (age) => {
  currentQuestion = 1
  return {
    getCurrentQuestion: () => { return getCurrentQuestion(age) },
    numberOfQuestions: age < 51 ? 6 : 4,
    answerQuestion
  }
}

export default MammService
